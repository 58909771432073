/* NOTIFICATIONS */
#notifications
  position: fixed
  top: 0
  right: 0
  margin: 20px
  transition: all 1s
  z-index: 2000

  .notification
    background-color: #daf1ff
    padding: 15px 21px
    border-radius: 10px
    border: 1px solid #b7cad6
    margin-bottom: 13px
    transition: all 500ms
    max-width: 400px
    color: #7c8e99
    box-shadow: 2px 3px 4px -2px #00000029

    .notification-title
      font-weight: bold
      font-size: 11pt
      color: #9ab1be
      padding: 0 0 4px 0

    .notification-message
      color: inherit
      font-size: 92%

    .notification-close
      color: #94b7cd
      text-decoration: none
      font-size: 9pt
      float: right
      z-index: 1

      &:hover
        text-decoration: underline

    &.notification-error
      background-color: #ffdae3
      color: #a27f87

      .notification-title, .notification-close
        color: #daa2b7

    &.notification-success
      background-color: #e2ffda
      color: #88a27f

      .notification-title, .notification-close
        color: #a2daa8
