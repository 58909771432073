.EnvironmentBanner
  background-color: #e77c00
  padding: 5px
  box-shadow: 0 0 10px #000a
  text-align: center
  z-index: 10000

  .name
    color: #fff
    font-weight: bold
    text-shadow: 0 1px 2px #0007