.SideBar
  flex: 0 0 150px
  box-shadow: 0 0 20px rgba(34, 36, 38, .15)
  text-align: center
  border: 0 solid transparent
  background: #1b1c1d
  height: 100%
  margin: 0
  overflow-y: auto
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif
  color: #fff
  position: relative
  z-index: 99
  display: flex
  flex-direction: column
  transition: all 200ms

  .content
    flex: 1

  .title
    &:hover, &:active
      background: none

    span
      font-weight: 800

  .SideBarItem
    cursor: pointer
    background: none
    user-select: none
    transition: all 100ms ease
    padding: 0.9em 1.1em
    line-height: 1
    border-bottom: 1px solid rgba(255, 255, 255, .08)
    display: block

    &, &:visited
      color: inherit
      text-decoration: none

    .icon
      display: block
      margin: 0 auto .5rem
      font-size: 1.7em

    span
      text-transform: uppercase
      font-size: 0.8em
      transition: all 200ms

    &:hover, &:active
      background-color: rgba(255, 255, 255, .08)
      color: inherit
      text-decoration: none

    &.active
      background-color: rgba(255, 255, 255, .15)
      color: inherit
      text-decoration: none

  .footer
    width: 100%

@media screen and (max-width: 900px)
  .SideBar
    flex: 0 0 85px

    .SideBarItem span
      font-size: 0.7em

@media screen and (max-width: 650px)
  .SideBar
    flex: 0 0 75px

    .SideBarItem span
        font-size: 0.5em
