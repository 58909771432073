@import "./../variables"

.LoadingSplash
  width: 100%
  height: 100%
  padding: 20px 0
  text-align: center
  background-color: $background-color
  display: flex
  align-items: center
  flex-direction: row
  opacity: 0.8
  user-select: none
  z-index: 10

  &.absolute
    position: absolute
    top: 0
    left: 0

  .content
    margin: 0 auto
    max-height: 100%

    .icon
      font-size: 5em
      color: #aaa

    .text
      padding: 20px
      color: #777
      font-size: 1.2em
      text-shadow: 2px 1px 3px #00000020
